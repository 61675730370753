export enum Language {
  German = "de",
  Italian = "it",
  English = "en",
}

export interface Occupancy {
  adults: number;
  children: number[];
}
