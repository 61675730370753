import { Language } from "./types";

type Translation = typeof de;

const de = {
  occupancyAdults: (adults: number) =>
    `${adults} ${adults === 1 ? "Erwachsener" : "Erwachsene"}`,
  occupancyChildren: (children: number[]) =>
    `${children.length} ${children.length === 1 ? "Kind" : "Kinder"} `,
  occupancyChildrenAges: (children: number[]) =>
    `(${children.join(", ")} ${
      children.length === 1 ? (children[0] === 1 ? "Jahr" : "Jahre") : "Jahre"
    })`,
  noAccommodationFound: "Es wurde keine Unterkunft gefunden.",
};

const it: Translation = {
  occupancyAdults: (adults: number) =>
    `${adults} ${adults === 1 ? "adulto" : "adulti"}`,
  occupancyChildren: (children: number[]) =>
    `${children.length} ${children.length === 1 ? "bambino" : "bambini"}`,
  occupancyChildrenAges: (children: number[]) =>
    `(${children.join(", ")} ${
      children.length === 1 ? (children[0] === 1 ? "anno" : "anni") : "anni"
    })`,
  noAccommodationFound: "Non è stato trovato un alloggio.",
};

const en: Translation = {
  occupancyAdults: (adults: number) =>
    `${adults} ${adults === 1 ? "adult" : "adults"}`,
  occupancyChildren: (children: number[]) =>
    `${children.length} ${children.length === 1 ? "child" : "children"}`,
  occupancyChildrenAges: (children: number[]) =>
    `(${children.join(", ")} ${
      children.length === 1 ? (children[0] === 1 ? "year" : "years") : "years"
    })`,
  noAccommodationFound: "No accommodation was found.",
};

const translations: { [language in Language]: Translation } = {
  [Language.German]: de,
  [Language.Italian]: it,
  [Language.English]: en,
};

export default translations;
